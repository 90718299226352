<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden border-b border-gray-200 sm:rounded-lg"
          style="box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09)"
        >
          <div
            class="w-full overflow-hidden origin-left transform opacity-75 bg-green-type-1 animate-progress"
            :class="{ hidden: !loading }"
            style="height: 2px"
          ></div>
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-white">
              <tr>
                <th v-for="field in fields" :key="field.key" scope="col">
                  <slot :name="`field_${field.key}`">
                    <div
                      class="p-4 text-xs font-bold tracking-wider text-left lg:p-8 text-gray-type-2"
                    >
                      {{ field.label }}
                    </div>
                  </slot>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-type-7">
              <template v-if="loading">
                <tr>
                  <td :colspan="fields.length" class="py-10 text-center">
                    <app-loading-indicator></app-loading-indicator>
                  </td>
                </tr>
              </template>
              <template v-else>
                <template v-if="errorLoading"></template>
                <template v-if="tableItems && tableItems.length">
                  <tr
                    v-for="(item, index) in tableItems"
                    :key="index"
                    @click="emitter.emit('click', item)"
                    class="cursor-pointer"
                  >
                    <td
                      v-for="field in fields"
                      :key="field.key"
                      class="py-5 pl-8 whitespace-nowrap"
                    >
                      <slot :name="field.key" v-bind:item="item">

                        <div v-if="field.filter" class="text-xs font-medium text-gray-type-3">
                          {{ field.filter(getPropPath(item, field.key)) }}
                        </div>
                        <div v-else class="text-xs font-medium text-gray-type-3">
                          {{ getPropPath(item, field.key) }}
                        </div>
                      </slot>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <slot name="no-items">
                    <tr>
                      <td :colspan="fields.length" class="py-3 text-center">
                        No data available
                      </td>
                    </tr>
                  </slot>
                </template>
              </template>
            </tbody>
            <tfoot
              class="bg-white"
              v-if="usePagination && (tableItems.length > items.length || tableItems.length)"
            >
              <tr>
                <td :colspan="fields.length" class="p-3">
                  <div class="flex flex-row-reverse items-center">
                    <div class="flex items-center space-x-4">
                      <button
                        :disabled="paginationIsInFirstPage"
                        class="flex items-center justify-center w-8 h-8 ml-4 rounded-full focus:outline-none focus:ring"
                        :class="
                          paginationIsInFirstPage
                            ? 'cursor-not-allowed text-gray-type-11'
                            : 'hover:bg-gray-type-7'
                        "
                        @click="prevPage"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 19l-7-7 7-7"
                          />
                        </svg>
                      </button>
                      <button
                        :disabled="paginationIsInLastPage"
                        class="flex items-center justify-center w-8 h-8 ml-4 rounded-full focus:outline-none focus:ring"
                        :class="
                          paginationIsInLastPage
                            ? 'cursor-not-allowed text-gray-type-11'
                            : 'hover:bg-gray-type-7'
                        "
                        @click="nextPage"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </button>
                    </div>
                    <div class="ml-4 text-xs font-medium text-gray-type-8">
                      {{ paginationStartIndex }} -
                      {{
                        Math.min(
                          items.length,
                          paginationItemsPerPage * pagination.currentPage
                        )
                      }}
                      of {{ items.length }}
                    </div>
                    <select
                      v-model="pagination.itemsPerPage"
                      class="p-2 ml-3 text-xs font-medium bg-gray-type-4 text-gray-type-8 focus:outline-none focus:ring"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                    <span class="text-xs font-medium text-gray-type-8"
                      >Rows per page:</span
                    >
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    fields: {
      type: Array,
      required: true,
    },
    loading: Boolean,
    errorLoading: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: "auto",
    },
    usePagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pagination: {
        itemsPerPage: 10,
        currentPage: 1,
      },
    };
  },
  computed: {
    paginationStartIndex() {
      return (
        this.paginationItemsPerPage * this.pagination.currentPage -
        this.paginationItemsPerPage +
        1
      );
    },
    paginationItemsPerPage() {
      return parseInt(this.pagination.itemsPerPage);
    },
    tableItems() {
      if (this.usePagination) {
        return this.items.slice(
          this.paginationStartIndex - 1,
          this.paginationStartIndex + this.paginationItemsPerPage - 1
        );
      }

      return this.items;
    },
    paginationTotalPages() {
      return Math.ceil(this.items.length / this.paginationItemsPerPage);
    },
    paginationIsInLastPage() {
      return this.pagination.currentPage == this.paginationTotalPages;
    },
    paginationIsInFirstPage() {
      return this.pagination.currentPage === 1;
    },
  },
  methods: {
    getPropPath(obj, path) {
      if (!path) {
        return;
      }
      const units = path.split(",");
      const vals = [];
      units.forEach((i) => {
        vals.push(this.getProp(obj, i.split(".")));
      })
      return vals.join(" ");
    },
    getProp(obj, props) {
      const prop = props.shift();
      if (!obj[prop] || !props.length) {
        return obj[prop];
      }
      return this.getProp(obj[prop], props);
    },
    prevPage() {
      let page = this.pagination.currentPage - 1;

      if (page < 1) {
        page = 1;
      }

      this.pagination.currentPage = page;
    },
    nextPage() {
      let page = this.pagination.currentPage + 1;

      if (page > this.paginationTotalPages) {
        page = this.paginationTotalPages;
      }

      this.pagination.currentPage = page;
    },
  },
};
</script>